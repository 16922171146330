import React, { useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { WiDaySunny, WiCloudy, WiRain, WiSnow, WiFog } from "react-icons/wi";
// import cityData from "./cities.json"; // Path to your JSON file

// import youtubeData from "./yotube.json";

// Configure default icon paths for Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const App = () => {
  const [city, setCity] = useState("");
  const [forecasts, setForecasts] = useState(null);
  const [location, setLocation] = useState(null);
  const [youtubeData, setyoutubeData] = useState([]);
  const [zoom, setZoom] = useState(10);

  const getRandomCity = async () => {
    const requestBody = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        anywhere: true,
        country: "",
      }),
    };

    try {
      const response = await fetch(
        "http://54.216.96.176/travels/random_travel_destination/",
        requestBody
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.status === 200) {
        setCity(data.city); // Update state with the received city
        fetchWeather(data.city); // Call fetchWeather function with the new city
      } else {
        throw new Error("Failed to fetch city");
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const fetchWeather = async (city) => {
    setCity(city);
    const api_response = await fetch(
      `https://scalable-api.ashisholi.tech/weather-forecast?city=${city}`
    );
    if (api_response.status === 404) {
      return "No city found";
    }
    let response = await api_response.json();
    let youtubeApiResponse = await fetch(
      "https://afyvb7t8u0.execute-api.us-east-1.amazonaws.com/prod/search_engine",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `${city} wether`,
        }),
      }
    );
    let parsedYtResponse = await youtubeApiResponse.json();
    let ytJsonResponse = JSON.parse(parsedYtResponse.body);
    console.log(ytJsonResponse);

    setForecasts(response.forecastData.DailyForecasts);
    setLocation({
      lat: response.geoData[0].GeoPosition.Latitude,
      lng: response.geoData[0].GeoPosition.Longitude,
    });
    setyoutubeData(ytJsonResponse);
  };

  const handleCitySearch = (e) => {
    e.preventDefault();
    if (city) {
      fetchWeather(city);
    }
  };

  const weatherIcon = (iconPhrase) => {
    if (iconPhrase.includes("Rain")) return <WiRain />;
    else if (iconPhrase.includes("Snow")) return <WiSnow />;
    else if (iconPhrase.includes("Cloudy")) return <WiCloudy />;
    else if (iconPhrase.includes("Fog")) return <WiFog />;
    return <WiDaySunny />;
  };

  const isToday = (date) => {
    const today = new Date();
    const currentDate = new Date(date);
    return currentDate.toDateString() === today.toDateString();
  };

  return (
    <div className="min-h-screen bg-blue-50 flex flex-col items-center py-4">
      <div className="w-3/5 text-center py-4">
        <h1 className="text-3xl font-semibold text-gray-700 mb-4">
          Explore the Sky Conditions
        </h1>
        <form
          className="flex justify-center items-center"
          onSubmit={handleCitySearch}
        >
          <input
            type="text"
            className="p-2 w-1/2 border border-gray-300 rounded-l-lg"
            placeholder="Enter city name..."
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r-lg"
            type="submit"
          >
            Search
          </button>
          <button
            onClick={getRandomCity}
            className="ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg"
          >
            Get Random City Weather
          </button>
        </form>
      </div>
      {forecasts && location && (
        <div
          className="w-4/5 bg-white rounded-lg shadow-lg p-5 my-5 flex"
          style={{ height: "60vh" }}
        >
          <div className="w-1/2 p-4 overflow-auto">
            <h2 className="text-xl font-bold mb-2">Weather Forecast</h2>
            {forecasts.map((forecast, index) => (
              <div className="w-full p-4 overflow-auto">
                <table className="w-full text-left border-collapse">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="px-4 py-2">Date</th>
                      <th className="px-4 py-2">Min/Max</th>
                      <th className="px-4 py-2">How's the day</th>
                      <th className="px-4 py-2">Advise for the day</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {forecasts.map((forecast, index) => ( */}
                    <tr
                      key={index}
                      className={isToday(forecast.Date) ? "font-bold" : ""}
                    >
                      <td className="border px-4 py-2">
                        {new Date(forecast.Date).toLocaleDateString()}
                      </td>
                      <td className="border px-4 py-2">
                        {forecast.Temperature.Minimum.Value}°
                        {forecast.Temperature.Minimum.Unit} /
                        {forecast.Temperature.Maximum.Value}°
                        {forecast.Temperature.Maximum.Unit}
                      </td>
                      <td className="border px-4 py-2">
                        {weatherIcon(forecast.Day.IconPhrase)}
                      </td>
                      <td className="border px-4 py-2">
                        {forecast.ForecastMessage}
                      </td>
                    </tr>
                    {/* ))} */}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <div className="w-1/2 p-4">
            <MapContainer
              center={location}
              zoom={zoom}
              scrollWheelZoom={true}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={location}></Marker>
            </MapContainer>
          </div>
        </div>
      )}
      {forecasts && (
        // At the end of the App component, before the closing `</div>` tag
        <div className="w-full mt-5">
          <h2 className="text-3xl font-semibold text-center text-gray-700 mb-4">
            Some informative videos about {city.toUpperCase()}
          </h2>
          <div className="flex flex-wrap justify-center items-center">
            {youtubeData.slice(0, 5).map((video, index) => (
              <div
                key={index}
                className="m-4 bg-white rounded-lg shadow overflow-hidden w-96"
              >
                <div className="p-5 flex flex-col justify-between h-full">
                  <div>
                    <h3 className="text-xl font-semibold text-gray-800 mb-1">
                      {video.title}
                    </h3>
                    <h5 className="text-sm text-gray-500 mb-6">
                      {video.description
                        ? `${video.description.substring(0, 100)}...`
                        : "No detailed description available."}
                    </h5>
                  </div>
                  <iframe
                    src={video.url.replace("watch?v=", "embed/")}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={video.title}
                    className="w-full"
                    style={{ height: "215px" }} // Height is fixed to maintain uniformity
                  ></iframe>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
